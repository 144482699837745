import './App.css';
import RoutesApp from './RoutesApp';
import { AppConfigProvider, availableAppLanguages, useAppConfig } from './Contexts/AppConfigContext';
import { Error500Provider } from './Contexts/Error500Context';
import { ErrorBoundary } from 'react-error-boundary';
import Page500 from './pages/Commons/500/500';
import { GetLanguageInUrl, IsLaguagePresentInUrl } from './utils/urls';
import { SessionDataProvider } from './Contexts/SessionDataContext';
import { CartProvider } from './Contexts/CartContexts';
import { useEffect, useState } from 'react';
import { CultureProvider } from '@beds2b-group/reusable-components';

import '../node_modules/@beds2b-group/calendar/dist/calendar.cjs.production.min.css'
import '../node_modules/@beds2b-group/reusable-components/dist/reusable-components.cjs.production.min.css'


import { BookingEngineProvider, useBookingEngineContext } from './Contexts/EngineDataContext';
import { Modal, ModalWrapper } from '@gluedigital/modal';
import AppDebuggerComponent from './components/appdebugger/AppDebuggerComponent';
import { GoogleMapsProvider } from './Contexts/GoogleMapsContext';
import RefreshCacheComponent from './components/common/RefreshCache/RefreshCache';
import { CurrencyProvider } from './Contexts/CurrencyContext';

declare global {
  interface Window {
    sessionStorageListenerAdded?: boolean;
  }
}

function App(params: any) {

  const currentPath = window.location.pathname;

  const availableLanguages = availableAppLanguages;

    // Verifica si la ruta actual no comienza con ninguno de los códigos de idioma disponibles
    if (!availableLanguages.some((langCode) => currentPath.startsWith(`/${langCode}`))) {
      // Redirige a la primera ruta de idioma disponible (puedes personalizar esto según tus necesidades)
      window.location.href = `/${availableLanguages[0]}/`;
    }
  
  const redirectToError : boolean = false;

  const [responsive, setResponsive] = useState<'mobile' | 'tablet' | 'desktop'>('mobile');
  
  function MyErrorFallback({ error } : any) {

    if (!redirectToError){
      return false;
    }

    var actualError = sessionStorage.getItem("last-error");

    if (actualError){
      const errors = JSON.parse(actualError);
      errors.push({ message: encodeURIComponent(error.message), method: 'generic-error', parameters: JSON.stringify(error.stack) });
      sessionStorage.setItem("last-error", JSON.stringify(errors));
    }else{
      sessionStorage.setItem("last-error", JSON.stringify([{ message: encodeURIComponent(error.message), method: 'generic-error', parameters: JSON.stringify(error.stack) }]));
    }

    const url = `${(IsLaguagePresentInUrl() ? GetLanguageInUrl() : '')}/error/app-error`;
    if (window.location.href.indexOf("/error/") === -1){
      
      window.location.href = url;
    }
    
    return <Page500 />
  }

  useEffect(() => {
    const updateWindowDimensions = () => {
        const width : number = window.innerWidth;

        if(width < 768){
            setResponsive("mobile");
        }else if(width < 992){
            setResponsive("tablet");
        }else{
            setResponsive("desktop");
        }
    };

    updateWindowDimensions();

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions) 

  }, []);

  let disableShowRefreshCache = true;
  let lastX = 0;
  let lastY = 0;
  let lastTime = 0;
  let speedThreshold = 1000; // Ajusta el umbral de velocidad aquí
  let maxVerticalMovement = 500; // Máximo movimiento en el eje Y permitido
  let cooldown = false;
  
  const [showRefreshCache, setShowRefreshCache] = useState<boolean>(false);
  if (!disableShowRefreshCache){
        document.addEventListener("mousemove", (event) => {
            if (cooldown) return;

            let currentTime = Date.now();
            let deltaTime = currentTime - lastTime;
            let deltaX = event.clientX - lastX;
            let deltaY = event.clientY - lastY;

        
            // Calcula la velocidad del movimiento en el eje X
            let speedX = Math.abs(deltaX / deltaTime);
        
            if (speedX > speedThreshold && Math.abs(deltaY) < maxVerticalMovement) {
              setShowRefreshCache(true);
                cooldown = true;
                // Evitar que se active el alert repetidamente
                setTimeout(() => {
                    cooldown = false;
                }, 1000); // Espera 1 segundo antes de volver a detectar
            }
        
            lastX = event.clientX;
            lastTime = currentTime;
        });
  }
  
  
  
  return (
    <ErrorBoundary FallbackComponent={MyErrorFallback}>
      <BookingEngineProvider>
        <Error500Provider>
            <CultureProvider initialCulture={{
              exchangeRates: {'EUR':1},
              localCurrency: 'EUR',
            }}>
              <SessionDataProvider>
                <CartProvider>
                  <GoogleMapsProvider>
                    <AppConfigProvider key='app-key-index' responsive={responsive}>
                      <CurrencyProvider>
                      <ModalWrapper>
                        <div className="App">
                          <AppDebuggerComponent/>
                          <Modal></Modal>
                          {
                            showRefreshCache && <RefreshCacheComponent setShowRefreshCache={setShowRefreshCache}/>
                          }
                          <RoutesApp i18n={params.i18n} />
                        </div>
                      </ModalWrapper>
                      </CurrencyProvider>
                    </AppConfigProvider>
                  </GoogleMapsProvider>
                </CartProvider>
              </SessionDataProvider>
            </CultureProvider>
        </Error500Provider>
      </BookingEngineProvider>
    </ErrorBoundary>
  );
}

export default App;
