import { createContext, useContext, useEffect, useState } from "react"
import AppConfig from "../models/AppConfig";
import { fetchClientAppCustomData } from '../api/repositoryEP';

export const availableAppLanguages : Array<string> = ['es','us','mx','pt','gb'];

const defaultconfigValues : AppConfig = {
    mainColor: "black",
    secondaryColor: "black",
    scripts: [],
    widget:{
        searchTheme: "dark",
        mainColor: "white",
        secondaryColor: "black",
        availableRoomTypes: ['individual', 'double','multiple'],
        maxGuestsPerRoom: 4,
        maxAdultsPerRoom: 4,
        maxChildrenPerRoom: 4,
        minGuestsPerRoom: 1,
        maxRooms: 10,
        minRooms: 1,

    },
    dateFormat: "DD/MM/YYYY",
    availableLanguages: [],
    siteName: '',
    legalTexts:{
        legalWarning: '',
        privacity: '',
        cookies: '',
        termsAndConditions: ''
    },
    images:{
        brandLogo: "",
        appLogo: "",
        loginBackGround: ""
    },
    availableUserTypes: [],
    availableCurrencies: [],
    prefixNames: [],
    customRegisterFields: [],
    hotels:[],
    contactPhones: [],
    contactFormParameters: {
        release: 1,
        minRoomsNumber: 1,
        maxRoomsNumber: 1,
        minOccupancyNumber: 1,
        maxOccupancyNumber: 1,
        availableBugetTypes: [],
        availableServices: [],
        sendCopyToAgent: false,
        mailTo: ""
    },
    currenciesExchange : []
};

const AppConfigContext = createContext<{config: AppConfig, device: 'mobile' | 'tablet' | 'desktop'}>({config: defaultconfigValues, device: 'desktop'});

export const AppConfigProvider: React.FC<{ children: React.ReactNode, responsive: 'mobile' | 'tablet' | 'desktop' }> = ({ children, responsive }) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [config, setConfig] = useState<AppConfig>(defaultconfigValues);
    const [device, setDevice] = useState<'mobile' | 'tablet' | 'desktop'>('desktop');

    useEffect(()=>{
        if (window.location.href.indexOf("/error/") == -1 && !isLoaded){

            var configInSessionStorage = sessionStorage.getItem("app-config");
            
            if (configInSessionStorage && configInSessionStorage != '{}'){
                setConfig(JSON.parse(configInSessionStorage));
                setIsLoaded(true);
            }else{
                //Revisamos si hay un parámetro llamado t en la url, si está presente, forzamos la recarga de la configuración
                const urlParams = new URLSearchParams(window.location.search);
                const t = urlParams.get('t');

                fetchClientAppCustomData(t ? true : false).then(response=> {
                    if (response){
                        setConfig((response.data as AppConfig));
                        sessionStorage.setItem("app-config", JSON.stringify(response.data));
                        setIsLoaded(true);
                    }
                })
                .finally(()=>{
                });
            }
        }
        
    },[]);

    useEffect(()=>{
        setDevice(responsive);
    },[responsive])

    return(
        <AppConfigContext.Provider value={{config, device}}>
            {children}
        </AppConfigContext.Provider>
    )
}

export const useAppConfig = () : {config: AppConfig, device: 'mobile' | 'tablet' | 'desktop'} => {
    return useContext(AppConfigContext);
}