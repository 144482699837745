

import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Bookings'

export const BookingsEP : EndPointConfig[] =[
        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetBooking"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("FilterBookings"),
        
]