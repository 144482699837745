
import { Col, List, Row, Typography } from 'antd';

import './NewsNotFeaturedListComponent.scss'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { NewsData } from '../../../models/NewsInfo';
import { CustomPagination } from '../..';
import { PageSize } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';



interface NewsNotFeaturedListComponentsProps {
    list: NewsData[],
    search: (isFeatured: boolean, rows: number, page: number) => void,
    totalElements: number,
    loading: boolean,
    prefixImg: string;
}

const NewsNotFeaturedListComponents = (props: NewsNotFeaturedListComponentsProps): JSX.Element => {
    const { t } = useTranslation();


    const [actualPageSize, setActualPageSize] = useState<number>(9);
    const [actualPage, setActualPage] = useState<number>(1);
    const defaultPageSize = PageSize;


    /////////////////NO TENGO CLARO DE QEU ESTO ESTÉ OK //////////////////////////
    const onChangePagination = (newPage: number, newPageSize?: number | undefined) => {
        setActualPage(newPage);
        setActualPageSize(newPageSize ?? defaultPageSize);
        props.search(false, newPage, newPageSize ?? defaultPageSize);
    }

    const navigate = useNavigate();

    const getUrlNew = (id: string): string => `${window.location.pathname}/${id}`;


    return (
        <div className="news-not-featured-list-component">
            <Row gutter={[16, 16]}>
                {props.list.map((item) => (
                    <Col key={item.id} xs={24} md={8}>
                        <Row
                            className="new-card"
                            onClick={() => navigate(getUrlNew(item.id))}
                        >
                            <Col span={24}>
                                <img
                                    className="img-featured"
                                    title={item.title}
                                    src={props.prefixImg + item.img}
                                    alt={item.title}
                                />
                            </Col>
                            <Col span={24} className="text-container">
                                <Typography.Paragraph
                                    ellipsis={{ rows: 4, expandable: true }}
                                    className="app-subtitle"
                                >
                                    {item.title}
                                </Typography.Paragraph>
                            </Col>
                        </Row>

                    </Col>
                ))}
            </Row>



            <div className="pagination-container">
                <CustomPagination actualPage={actualPage} actualPageSize={actualPageSize} totalElements={props.totalElements} onChange={onChangePagination} />
            </div>
        </div>
    )
}

export default NewsNotFeaturedListComponents;
