import {  Select, Skeleton } from 'antd'
import { useEffect, useState } from 'react';
import { useAppConfig } from '../../../Contexts/AppConfigContext';


import './CurrencySelectorComponent.scss';
import { GetArrowSelectIcon } from '../../../utils/icons';
import { Currency } from '../../../models/Currency';
import { useCurrencyData } from '../../../Contexts/CurrencyContext';
import { useSessionData } from '../../../Contexts/SessionDataContext';

const CurrencySelectorComponent = () : JSX.Element =>{

    const appConfig = useAppConfig();
    const userDataSession = useSessionData();
    const { currencyData, changeCurrencyISO, doExchangeCurrency } = useCurrencyData();

    const [availableClientCurrencies, setAvailableClientCurrencies] = useState<Currency[]>([]);

    const getAvailableClientCurrencies = () : void => {
        if (appConfig && appConfig.config.availableCurrencies){
            setAvailableClientCurrencies(appConfig.config.availableCurrencies);
        }
    }

    const changeCurrency = (shortCode: string) : void => {
        changeCurrencyISO(shortCode);
    }

    useEffect(()=>{
        getAvailableClientCurrencies();

    },[appConfig])

    useEffect(()=>{
        if(userDataSession.userData){
            changeCurrencyISO(userDataSession.userData.currency ?? 'EUR' );
        }

    },[userDataSession])

    return (
        <>
            {
                availableClientCurrencies && availableClientCurrencies.length > 0
                ?
                    <Select className='currency-selector' defaultValue={currencyData.currencySelectedISO} onChange={changeCurrency} suffixIcon={GetArrowSelectIcon()}>
                        {
                            availableClientCurrencies.map((c: Currency) => {
                                return <Select.Option disabled={!c.available} key={c.iso}>{c.description} ({c.iso})</Select.Option>
                            })
                        }
                    </Select>
                :
                    <Skeleton.Input active size={'small'} />
            }
        </>
    )
}

export default CurrencySelectorComponent;