import { AgenciesEP } from './AgenciesEP';
import { AppCustomDataEP } from './AppCustomDataEP'
import { BookingEngineEP } from './BookingEngineEP';
import { BookingsEP } from './BookingsEP';
import { CompaniesEP } from './CompaniesEP';
import {ContactEP} from './ContactEP';
import { ErrorNotificationsEP } from './ErrorNotificationsEP';
import { HotelsEP } from './HotelsEP';
import { NewsEP } from './NewsEP';
import { OrganizationEP } from './OrganizationEP';
import { RegimesEP } from './RegimesEP';
import { UsersEP } from './UsersEP';

export const RegisterEndPoints = []
    .concat(AgenciesEP)
    .concat(AppCustomDataEP)
    .concat(BookingEngineEP)
    .concat(BookingsEP)
    .concat(CompaniesEP)
    .concat(ContactEP)
    .concat(ErrorNotificationsEP)
    .concat(HotelsEP)
    .concat(OrganizationEP)
    .concat(RegimesEP)
    .concat(UsersEP)
    .concat(NewsEP)
    ;
