

import { useTranslation } from 'react-i18next'
import './PostponedBookingsComponent.scss'
import { Alert, Col, Row, Typography } from 'antd'
import PostponedBooking from '../../../models/PostponedBooking'
import { GetBusinessIcon, GetCalendarIcon } from '../../../utils/icons'
import { useAppConfig } from '../../../Contexts/AppConfigContext'
import moment from 'moment'
import HTMLReactParser from 'html-react-parser'
import { DotsActions, NoData } from '../..'
import { useState } from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

interface PostponedBookingsComponentProps {
    list: PostponedBooking[]
}

const PostponedBookingsComponent = (props: PostponedBookingsComponentProps) : JSX.Element => {
    const { list } = props
    const appConfig = useAppConfig();

    const [limit, setLimit] = useState<number>(2);

    const showAll = () => {
        setLimit(list.length);
    }

    const showLess = () => {
        setLimit(2);
    }

    const { t } = useTranslation()

    return(
        <div className='postponed-bookings-component'>
            <Typography.Title level={3} className="app-title">
                {t('components.postponed-bookings.title')}
            </Typography.Title>
            
            {
                list?.length > 0 &&
                list.slice(0, limit)
                .map((booking: PostponedBooking, index: number) => {
                    return <div className="app-card postponed-booking" key={index}>
                                <div className="app-card-header ">
                                    <div className="actions-info">
                                        <DotsActions actions={[
                                                {
                                                    label: t('components.postponed-bookings.resume'),
                                                },
                                                {
                                                    label: t('components.postponed-bookings.delete'),
                                                }
                                            ]}/>
                                    </div>
                                </div>
                                <div className="app-card-body">
                                    <Row gutter={16}>
                                        <Col xs={24} lg={12} className="hotel-info">
                                            <div>
                                                {GetBusinessIcon('app-icon width-24px')}
                                            </div>
                                            <div>
                                                <span>
                                                    {booking.hotelName}
                                                </span>
                                                <span>
                                                    {booking.hotelPhone}
                                                </span>
                                                <span>
                                                    {booking.hotelAddress}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs={24} lg={12} className="locator-info">
                                            <div>
                                            <span className="locator-number">
                                                {HTMLReactParser(t('components.postponed-bookings.locator', [booking.locator]))} 
                                            </span>
                                            <span>
                                                {HTMLReactParser(t('components.postponed-bookings.owner-name', [booking.ownerName]))}
                                            </span>
                                            <span>
                                                {HTMLReactParser(t('components.postponed-bookings.owner-email', [booking.ownerEmail]))}
                                            </span>
                                            <span>
                                                {HTMLReactParser(t('components.postponed-bookings.owner-phone', [booking.ownerPhone]))}
                                            </span>
                                            </div>
                                        </Col>
                                        
                                        <Col xs={24} lg={12} className="booking-info">
                                            <div>
                                                {GetCalendarIcon('app-icon width-24px')}
                                            </div>
                                            <div>
                                                <span>{HTMLReactParser(t('components.postponed-bookings.dates', [moment(booking.checkIn).format(appConfig.config.dateFormat), moment(booking.checkOut).format(appConfig.config.dateFormat)]))}</span>
                                                <span>{HTMLReactParser(t('components.postponed-bookings.register-date', [moment(booking.creationDate).format(appConfig.config.dateFormat)]))}</span>
                                                <span className="expired-date">{HTMLReactParser(t('components.postponed-bookings.expiration-date', [moment(booking.expirationDate).format(appConfig.config.dateFormat)]))}</span>

                                                {
                                                    booking.nearExpirationDate &&
                                                <Alert className="alert-caducity" message={t('components.postponed-bookings.alert-expired-date')} type="warning" showIcon closable />
                                                }    
                                            
                                            </div>

                                        </Col>
                                        <Col xs={24} lg={12} className="pricing-info">
                                            <div>
                                                <span className="pricing-title">{HTMLReactParser(t('components.postponed-bookings.pricing'))}</span>
                                                <span>
                                                    {HTMLReactParser(t('components.postponed-bookings.pvp', [booking.pvp, booking.currencySymbol]))}
                                                </span>
                                                <span>
                                                    {HTMLReactParser(t('components.postponed-bookings.commission', [booking.commission, booking.currencySymbol]))}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="app-card-footer">
                                </div>
                            </div>
                })
            }

            <div className="show-all-less-container">
                {
                    list?.length == 0 && <NoData />
                }

                {
                    list?.length > limit && limit === 2 &&
                        <div className="show-all" onClick={showAll}>
                            {t('components.postponed-bookings.show-more')} <DownOutlined />
                        </div>
                }

                {
                    list?.length === limit &&
                        <div className="show-less" onClick={showLess}>
                            {t('components.postponed-bookings.show-less')} <UpOutlined />
                        </div>
                }
            </div>
        </div>
    )
}

export default PostponedBookingsComponent;