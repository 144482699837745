import { BrowserRouter as Router, Routes, Route, useLocation, Outlet, Navigate } from 'react-router-dom';

// Private Pages
import UsersPage from './pages/Private/UsersPage/UsersPage';
import BookingEnginePage from './pages/Private/BookingEnginePage/BookingEnginePage';
import BookingsPage from './pages/Private/BookingsPage/BookingsPage';
import PrivateContactPage from './pages/Private/ContactPage/ContactPage';
import DashboardPage from './pages/Private/DashboardPage/DashboardPage';

// Public Pages
import LandingPage from './pages/Public/LandingPage/LandingPage';
import LoginPage from './pages/Public/LoginPage/LoginPage';
import RegisterPage from './pages/Public/RegisterPage/RegisterPage';
import PublicContactPage from './pages/Public/ContactPage/ContactPage';

// Common pages
import Page404 from './pages/Commons/404/404';
import Page500 from './pages/Commons/500/500';
import Page403 from './pages/Commons/403/403';
import TermnsAndConditionsPage from './pages/Commons/LegalTexts/TermsAndConditions/TermnsAndConditionsPage';
import PrivacityPage from './pages/Commons/LegalTexts/Privacity/PrivacityPage';
import LegalWarningPage from './pages/Commons/LegalTexts/LegalWarning/LegalWarningPage';
import CookiesPage from './pages/Commons/LegalTexts/Cookies/CookiesPage';


import { useEffect, useState } from 'react';
import { DEFAULT_LANGUAGE } from './i18n/i18n';
import { IsLaguagePresentInUrl } from './utils/urls';
import { useSessionData } from './Contexts/SessionDataContext';
import PrivatePageWrapper from './pages/Private/PrivatePageWrapper';
import PublicPageWrapper from './pages/Public/PublicPageWrapper';
import ProfilePage from './pages/Private/ProfilePage/ProfilePage';
import CustomerVoucherPage from './pages/Private/CustomerVoucherPage/CustomerVoucherPage';
import GroupContactPage from './pages/Private/GroupContactPage/GroupContactPage';
import LoadingPage from './pages/Commons/LoadingPage/LoadingPage';
import BookingPaymentOkPage from './pages/Private/BookingPaymentResultPage/BookingPaymentOkPage';
import BookingPaymentKOPage from './pages/Private/BookingPaymentResultPage/BookingPaymentKOPage';
import NewsPage from './pages/Private/NewsPage/NewsPage';
import NewsInfoPage from './pages/Private/NewsInfoPage/NewsInfoPage';


let useLocationStr = '';

export const GetAppLanguage = () : string => useLocationStr.length > 0 ? useLocationStr : DEFAULT_LANGUAGE;

export const ChangeAppLanguage = (params: any) => {
  const location = useLocation();
  let iso18Info : any = null;

  useEffect(()=>{
    useLocationStr = '';
    if (IsLaguagePresentInUrl()){
      useLocationStr = location.pathname.split('/')[1];
    }
    
    params.i18n.changeLanguage(useLocationStr);

    iso18Info = params.i18n;

  },[params.i18n])
  return <></>;
};

export const UpdateAppLanguage = (shortCode: string) : void => {
  if (IsLaguagePresentInUrl()){
    var path = window.location.pathname.split("/")
    path[1] = shortCode;
    window.location.href = path.join("/");
  }else{
    const pathName = window.location.pathname && window.location.pathname.length > 0 ? `${window.location.pathname}` : '';
    const finalUrl = `/${shortCode}${pathName}`;

    window.location.href = finalUrl;
  }
}


interface RoutesAppParams {
  i18n: any
}
const RoutesApp = (params: RoutesAppParams) : JSX.Element => {
  const sessionData = useSessionData();
  
  const isLogged = sessionData.isLogged;
  const [language, setLanguage] = useState<string>('');

  useEffect(()=>{
    setLanguage(useLocationStr);
  },[useLocationStr])

  return (
    <Router>
      <ChangeAppLanguage i18n={params.i18n} />
      {
        isLogged ? <PrivateRoutes language={language} />  : <PublicRoutes  language={language}/>
      }
    </Router>
  );
}


interface RouteParams {
  language: string
}
const PrivateRoutes = (params : RouteParams) : JSX.Element => {

  const [language, setLanguage] = useState<string>(params.language);

  useEffect(()=>{
    setLanguage(params.language);
  },[params.language])

  return (
      <Routes>
        <Route path={`/:language/private/users`} element={<PrivatePageWrapper children={<UsersPage />} /> } />
        <Route path={`/:language/private/booking-engine`} element={<PrivatePageWrapper children={<BookingEnginePage />} /> } />
        <Route path={`/:language/private/booking-page`} element={<PrivatePageWrapper children={<BookingsPage />} /> } />
        <Route path={`/:language/private/news-page`} element={<PrivatePageWrapper children={<NewsPage />} /> } />
        <Route path={`/:language/private/news-page/:id`} element={<PrivatePageWrapper children={<NewsInfoPage />} /> } />

        <Route path={`/:language/private/customer-voucher/:locator`} element={<PrivatePageWrapper children={<CustomerVoucherPage />} /> } />
        <Route path={`/:language/private/payment-ok-customer-voucher/:locator`} element={<PrivatePageWrapper children={<BookingPaymentOkPage />} /> } />
        <Route path={`/:language/private/payment-ko`} element={<PrivatePageWrapper children={<BookingPaymentKOPage />} /> } />
        
        <Route path={`/:language/private/contact-page`} element={<PrivatePageWrapper children={<PrivateContactPage />} />} />
        <Route path={`/:language/private/groups-and-conventions`} element={<PrivatePageWrapper children={<GroupContactPage />} />} />
        <Route path={`/:language/private/dashboard`} element={<PrivatePageWrapper children={<DashboardPage />} />} />
        <Route path={`/:language/private/profile`} element={<PrivatePageWrapper children={<ProfilePage />} /> } />
        <Route path={`/:language/private/forbiden/*`} element={<PrivatePageWrapper children={<Page403 />} />} />
        <Route path={`/:language/private/error/*`} element={<PrivatePageWrapper children={<Page500 />} />} />
        <Route path={`/:language/private/*`} element={<PrivatePageWrapper children={<Page404 />} />} />
        <Route path={`/:language/private/403`} element={<PrivatePageWrapper children={<Page403 />} />} />
        <Route path={`/:language/private/404`} element={<PrivatePageWrapper children={<Page404 />} />} />
        <Route path={`/:language/private/500`} element={<PrivatePageWrapper children={<Page500 />} />} />
        
        <Route path={`/:language/private/loading-page`} element={<PrivatePageWrapper children={<LoadingPage />} />} />
      </Routes>
  );
}

const PublicRoutes = (params : RouteParams) : JSX.Element => {
  const [language, setLanguage] = useState<string>(params.language);

  useEffect(()=>{
    setLanguage(params.language);
    if (window.location.pathname.indexOf("private")===-1){
      sessionStorage.removeItem("user-data");
    }
  },[params.language])
  

  return (
      <Routes>
        <Route path="/:language/" element={<PublicPageWrapper showMenu widthPage='width-80' children={<LandingPage />} />} />
        <Route path="/:language/login" element={<PublicPageWrapper showMenu children={<LoginPage />} />} />
        <Route path="/:language/register" element={<PublicPageWrapper widthPage='width-80' showMenu children={<RegisterPage />} />} />
        <Route path="/:language/contact" element={<PublicPageWrapper showMenu widthPage='width-80' children={<PublicContactPage />} />} />
        <Route path="/:language/termns-and-conditions" element={<PublicPageWrapper showMenu widthPage='width-80' children={<TermnsAndConditionsPage />} />} />
        <Route path="/:language/privacity" element={<PublicPageWrapper showMenu widthPage='width-80' children={<PrivacityPage />} />} />
        <Route path="/:language/legal-warning" element={<PublicPageWrapper showMenu widthPage='width-80' children={<LegalWarningPage />} />} />
        <Route path="/:language/cookies" element={<PublicPageWrapper showMenu widthPage='width-80' children={<CookiesPage />} />} />

        <Route path="/:language/error/*" element={<PublicPageWrapper showMenu widthPage='width-80' children={<Page500 />} />} />
        <Route path="/:language/forbiden/*" element={<PublicPageWrapper showMenu widthPage='width-80' children={<Page403 />} />} />
        <Route path="/:language/*" element={<PublicPageWrapper showMenu widthPage='width-80' children={<Page404 />} />} />

        <Route path={`/:language/forbiden/*`} element={<Page403 />} />
        <Route path={`/:language/*`}  element={<Page404 />} />

        <Route path={`/:language/private/*`} element={<PublicPageWrapper onlyContent widthPage='width-80' children={<LoadingPage />} />} />
      </Routes>
  );
}

export default RoutesApp;