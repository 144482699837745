import './MoneyComponent.scss';
import { Tooltip } from "antd";
import { useCurrencyData } from '../../../Contexts/CurrencyContext';
import { useEffect, useState } from 'react';


interface MoneyComponentProps {
    amountOriginal: number;
    currencyOriginal: string;
}

const MoneyComponent = ({ amountOriginal, currencyOriginal }: MoneyComponentProps): JSX.Element => {
    const { currencyData, doExchangeCurrency } = useCurrencyData();
    const [amountExchanged, setAmountExchanged] = useState<number>(0);

    // Actualiza el monto convertido cuando cambian los props o la moneda seleccionada
    useEffect(() => {
        if (amountOriginal && currencyOriginal) {
            const amount = doExchangeCurrency(
                currencyOriginal,
                currencyData.currencySelectedISO,
                amountOriginal
            );
            setAmountExchanged(amount);
        }
    }, [amountOriginal, currencyOriginal, currencyData.currencySelectedISO, doExchangeCurrency]);

    return (
        <div className="money-component">
            <Tooltip            
                title={currencyData.currencySelectedISO != currencyOriginal ? `${amountOriginal} ${currencyOriginal} ` : ''}
            >
                {amountExchanged} {currencyData.currencySelectedISO}
            </Tooltip>
        </div>
    );
};

export default MoneyComponent;
