
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Upload, UploadProps, notification } from 'antd';
import { ApiOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { useSessionData } from '../../../Contexts/SessionDataContext';
import HTMLReactParser from 'html-react-parser';
import { useEffect, useState } from 'react';
import { createAvatar } from '@dicebear/core';
import { identicon } from '@dicebear/collection';

import './EditProfileComponent.scss'
import UpdatePersonalData from '../../../models/UpdatePersonalData';
import { isValidEmail } from '../../../api/repositoryEP';
import { use } from 'echarts';

interface EditProfileComponentProps {
    onSaveProfile: (data: UpdatePersonalData) => void;
    onSaveContactData: (data: UpdatePersonalData) => void;
    onSavePassword: (data: UpdatePersonalData) => void;
    onSaveRewardsId: (data: UpdatePersonalData) => void;
    onSaveNotifications: (data: UpdatePersonalData) => void;
    savingIndex: number;
}

//Size in KB
const maxImageSize = 512;
const EditProfileComponent = (props: EditProfileComponentProps): JSX.Element => {
    const { t } = useTranslation();
    const [emailValue, setEmailValue] = useState("");
    useEffect(() => {
        setEmailValue(sessionData?.userData?.email ?? "");
    }, []);





    const uploaderProps: UploadProps = {
        beforeUpload: (file) => {
            const isValidImg = file.type === 'image/png' || file.type === 'image/jpeg';
            if (!isValidImg) {
                notification.error({
                    message: t('components.edit-profile.avatar-error')!,
                    description: t('components.edit-profile.avatar-error-description')!,
                });
            }

            //permitimos solo imagenes de 512KB
            const isLt512K = file.size / 1024 < maxImageSize;

            if (isValidImg && !isLt512K) {
                notification.error({
                    message: t('components.edit-profile.avatar-error')!,
                    description: t('components.edit-profile.avatar-error-description-size', [maxImageSize])!,
                });
            }

            return isValidImg && isLt512K;
        },
        onChange: (info) => {
            console.log(info.fileList);
        },
    };

    const { savingIndex } = props;

    const sessionData = useSessionData();

    const [avatarImage, setAvatarImage] = useState<string>('');

    const [formPersonalData] = useForm();
    const [formContactData] = useForm();
    const [formPassword] = useForm();
    const [formRewards] = useForm();
    const [formNotifications] = useForm();



    const onSavePersonalData = (): void => {
        props.onSaveProfile(formPersonalData.getFieldsValue() as UpdatePersonalData);
    }

    const onSaveContactData = (): void => {
        props.onSaveContactData(formContactData.getFieldsValue() as UpdatePersonalData);
    }

    const onSavePassword = (): void => {
        props.onSavePassword(formPassword.getFieldsValue() as UpdatePersonalData);
    }

    const onSaveRewardsId = (): void => {
        props.onSaveRewardsId(formRewards.getFieldsValue() as UpdatePersonalData);
    }

    const onSaveNotifications = (): void => {
        const formData = formNotifications.getFieldsValue() as UpdatePersonalData;
        formData.notifyEmail = formNotifications.getFieldValue("notifyEmail") ? new Date() : null;
        console.log("El notify email es : ", formData.notifyEmail);
        props.onSaveNotifications(formData);
    };

    const [disableButtonIndex, setDisableButtonIndex] = useState<number>(0);
    useEffect(() => {
        setDisableButtonIndex(savingIndex);
    }, [savingIndex])

    useEffect(() => {
        if (sessionData) {
            const avatar = createAvatar(identicon, {
                seed: sessionData.userData.email,
                // ... other options
            });

            if (avatar) {
                setAvatarImage(avatar.toString());
            }
        } else {
            console.log("ERROR: No se ha podido generar el avatar");
        }


    }, [sessionData])


    return (
        <div className="edit-profile-component">
            <Row gutter={16}>
                <Col xs={24} lg={4}>
                    <Divider className='app-divider' orientation='left'>
                        {t('components.edit-profile.avatar')}
                    </Divider>
                    <div className="avatar-image">
                        <Upload
                            {...uploaderProps}
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                        >
                            {
                                sessionData?.userData?.avatar ? <img src={sessionData?.userData?.avatar} /> : HTMLReactParser(avatarImage)
                            }
                        </Upload>
                        <small className="avatar-description">
                            {t('components.edit-profile.avatar-description')}
                        </small>
                    </div>

                </Col>
                <Col xs={24} lg={20}>
                    <Divider className='app-divider' orientation='left'>{t('components.edit-profile.personal-data')}</Divider>

                    <Form
                        form={formPersonalData}
                        layout="vertical"
                        name="edit-profile-form"
                        scrollToFirstError
                        onFinish={onSavePersonalData}
                    >
                        <Row gutter={16}>



                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label={t("components.edit-profile.name")}
                                    name="name"
                                    initialValue={sessionData?.userData?.name}
                                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                >
                                    <Input allowClear className="app-input" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label={t("components.edit-profile.surname")}
                                    name="surname"
                                    initialValue={sessionData?.userData?.surname}
                                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                >
                                    <Input allowClear className="app-input" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label={t("components.edit-profile.second-surname")}
                                    name="secondSurname"
                                    initialValue={sessionData?.userData?.secondSurname}
                                >
                                    <Input allowClear className="app-input" />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <div className="actions">
                                    <Button className="app-button send-form-data" disabled={savingIndex === 1} htmlType='submit'>
                                        {savingIndex === 1 ? <LoadingOutlined /> : <SaveOutlined />} {t('components.edit-profile.update')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <Divider className='app-divider' orientation='left'>{t('components.edit-profile.contact-data')}</Divider>

                    <Form
                        form={formContactData}
                        layout="vertical"
                        name="edit-profile-form"
                        scrollToFirstError
                        onFinish={onSaveContactData}
                    >
                        <Row gutter={16}>
                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label={t("components.edit-profile.email")}
                                    name="email"
                                    initialValue={sessionData?.userData?.email}
                                    rules={[
                                        {
                                            validator: async (_, value) => {
                                                // Verifica si el campo está vacío
                                                if (!value) {
                                                    return Promise.reject((t("forms.validations.required-field")));
                                                }

                                                // Validación de formato de correo electrónico
                                                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                                                    return Promise.reject((t("forms.validations.invalid-format")));
                                                }

                                                // Permitir si el email es igual al actual
                                                if (value === sessionData?.userData?.email) {
                                                    return Promise.resolve();
                                                }

                                                // Validación de si el email está en uso
                                                const response = await isValidEmail(value);
                                                if (response?.type === 1 && response?.data === false) {
                                                    return Promise.reject((t("forms.validations.invalid-email"))); // Email no es válido
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        allowClear
                                        value={emailValue}
                                        onChange={(e) => setEmailValue(e.target.value)}
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        className="app-input"
                                    />
                                </Form.Item>


                            </Col>

                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label={t("components.edit-profile.repeat-email")}
                                    name="emailRepeat"
                                    initialValue={sessionData?.userData?.email}
                                    rules={[
                                        { required: true, message: t("forms.validations.required-field")! },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("email") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(t("forms.validations.not-match"));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        allowClear
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        className="app-input"
                                    />
                                </Form.Item>
                            </Col>


                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label={t("components.edit-profile.phone")}
                                    initialValue={sessionData?.userData?.phone}
                                    name="phone"

                                >
                                    <Input allowClear className="app-input" />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <div className="actions">
                                    <Button className="app-button send-form-data" disabled={savingIndex === 2} htmlType='submit'>
                                        {savingIndex === 2 ? <LoadingOutlined /> : <SaveOutlined />} {t('components.edit-profile.update')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <Divider className='app-divider' orientation='left'>{t('components.edit-profile.password-and-username')}</Divider>

                    <Form
                        form={formPassword}
                        layout="vertical"
                        name="edit-profile-form"
                        scrollToFirstError
                        onFinish={onSavePassword}
                    >
                        <Row gutter={16}>
                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label={t("components.edit-profile.username")}
                                    name="userName"
                                    initialValue={sessionData?.userData?.username}
                                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                >
                                    <Input allowClear className="app-input" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label={t("components.edit-profile.password")}
                                    name="password"
                                >
                                    <Input.Password className="app-input" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label={t("components.edit-profile.repeat-password")}
                                    name="passwordRepeat"
                                    dependencies={['password']}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const password = getFieldValue("password");
                                                if ((!password && !value) || password === value) {
                                                    // Si ambos están vacíos o coinciden, es válido
                                                    return Promise.resolve();
                                                }
                                                // Caso en el que el password no coincide
                                                return Promise.reject(t("forms.validations.not-match"));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password className="app-input" />
                                </Form.Item>

                            </Col>



                            <Col xs={24}>
                                <div className="actions">
                                    <Button className="app-button send-form-data" disabled={savingIndex === 3} htmlType='submit'>
                                        {savingIndex === 3 ? <LoadingOutlined /> : <SaveOutlined />} {t('components.edit-profile.update')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <Divider className='app-divider' orientation='left'>{t('components.edit-profile.rewards-vinculation')}</Divider>
                    <Form
                        form={formRewards}
                        layout="vertical"
                        name="edit-profile-form"
                        scrollToFirstError
                        onFinish={onSaveRewardsId}
                    >
                        <Row gutter={16}>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label={t("components.edit-profile.rewardsId")}
                                    name="rewardsId"
                                    initialValue={sessionData?.userData?.UAID}

                                >
                                    <Input allowClear className="app-input" />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <div className="actions">
                                    <Button className="app-button send-form-data" style={{ marginRight: 20 }} >
                                    <ApiOutlined/>    {t('components.edit-profile.rewards-connection')}
                                    </Button>
                                    <Button className="app-button send-form-data" disabled={savingIndex === 4} htmlType='submit'>
                                        {savingIndex === 4 ? <LoadingOutlined /> : <SaveOutlined />} {t('components.edit-profile.update')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>


                    <Divider className='app-divider' orientation='left'>{t('components.edit-profile.notifications-tittle')}</Divider>
                    <Form
                        form={formNotifications}
                        layout="vertical"
                        name="edit-profile-form"
                        scrollToFirstError
                        onFinish={onSaveNotifications}
                    >
                        <Row gutter={16}>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    name="notifyEmail"
                                    valuePropName="checked"
                                    initialValue={sessionData?.userData?.notifyEmail || false} // Asigna el valor inicial adecuado
                                >
                                    <Checkbox className="app-input"> {t("components.edit-profile.notifications")} </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>

                                <div className="actions">

                                    <Button className="app-button send-form-data" disabled={savingIndex === 5} htmlType='submit'>
                                        {savingIndex === 5 ? <LoadingOutlined /> : <SaveOutlined />} {t('components.edit-profile.update')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>





                </Col>
            </Row>
        </div>
    )
}

export default EditProfileComponent;