import { useTranslation } from "react-i18next";
import { FeaturedNewsList, NotFeaturedNewsList } from "../../../components";
import { useEffect, useState } from "react";
import './NewsPage.scss';
import { NewsData, NewsFilter } from "../../../models/NewsInfo";
import { PageSize } from "../../../utils/constants";
import { fetchActiveNews } from "../../../api/repositoryEP";
import LoadingDataComponent from "../../../components/common/LoadingData/LoadingDataComponent";


const NewsPage = () : JSX.Element => {
    
    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [featuredNewsList,setFeaturedNewsList] = useState<NewsData[]>([]);

    const [notFeaturedNewsList,setNotFeaturedNewsList] = useState<NewsData[]>([]);
    const [totalElementsNotFeatured,setTotalElementsNotFeatured] = useState<number>(0);
    const [pageNotFeatured,setPageNotFeatured] = useState<number>(1);
    const [pageSizeNotFeatured,setPageSizeNotFeatured] = useState<number>(PageSize);
    const [prefixImg,setPrefixImg] = useState<string>('');
    


    const getNews = async (isFeatured: boolean, page: number, pageSize: number): Promise<void> => {

        const filter = {
            isFeatured: isFeatured,
            numRecords: pageSize,
            page: page
        } as NewsFilter;
    
        const response = await fetchActiveNews(filter);
        if (response?.type === 1) {
            if (isFeatured) {
                setFeaturedNewsList(response.data.elements);
            } else {
                setNotFeaturedNewsList(response.data.elements);
                setTotalElementsNotFeatured(response.data.totalRecords);
                setPageNotFeatured(response.data.page);
                setPageSizeNotFeatured(response.data.pageSize);
            }
            setPrefixImg(response.data.prefix);
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
            setLoadingData(true); // Activar la carga
            await Promise.all([
                getNews(true, 1, 9999), // Noticias destacadas
                getNews(false, pageNotFeatured, pageSizeNotFeatured), // Noticias no destacadas
            ]);
            setLoadingData(false); // Desactivar la carga cuando ambas finalicen
        };
    
        fetchData();
    }, []);
    

    return(
       
        <div className="news-page">
             {loadingData && <LoadingDataComponent/> }
             {!loadingData && (
                 <>
                     <h2 className="title">{t('pages.news-page.title')}</h2>

                     <div>
                         <FeaturedNewsList loading={loadingData} list={featuredNewsList} prefixImg={prefixImg}/>
                     </div>
                     <h2 className="title">{t('pages.news-page.more-news')}</h2>
                     <div >
                         <NotFeaturedNewsList loading={loadingData} list= {notFeaturedNewsList} totalElements={totalElementsNotFeatured} search={getNews}  prefixImg={prefixImg}/>
                     </div>
                 </>
             )}
           
        </div>
    )
}

export default NewsPage;