import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'AppConfig'

export const AppCustomDataEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetCustomAppData"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetClientTemplate")
      
]