import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'News'

export const NewsEP : EndPointConfig[] =[
        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetActiveNews"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetNewsDataAW"),   

]