import { Col, List, Row, Typography } from 'antd';
import './NewsFeaturedListComponents.scss';
import { Link, useNavigate } from 'react-router-dom';
import { NewsData } from '../../../models/NewsInfo';

interface NewsFeaturedListComponentsProps {
    loading: boolean;
    list: NewsData[];
    prefixImg: string;
}

const NewsFeaturedListComponents = (props: NewsFeaturedListComponentsProps): JSX.Element => {
    const navigate = useNavigate();

    const getUrlNew = (id: string): string => `${window.location.pathname}/${id}`;

    return (
        <div className="news-featured-list-component">
            <List
                itemLayout="vertical"
                size="large"
                dataSource={props.list}
                renderItem={(item, i) => (
                    <List.Item key={item.id}>
                        <Link
                            id={item.id}
                            to={getUrlNew(item.id)}
                            onClick={() => navigate(getUrlNew(item.id))}
                        >
                            <Row gutter={[16, 0]}>
                                {i % 2 === 0 ? (
                                    <>
                                        {/* Imagen a la izquierda */}
                                        <Col xs={24} md={12}>
                                            <img
                                                className="img-featured"
                                                title={item.title}
                                                src={props.prefixImg + item.img}
                                                alt={item.title}
                                            />
                                        </Col>
                                        {/* Título a la derecha */}
                                        <Col xs={24} md={12} className="text-container" >
                                            <Typography.Paragraph
                                                ellipsis={{ rows: 4, expandable: true }}
                                                className="app-subtitle"
                                            >
                                                {item.title}
                                            </Typography.Paragraph>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        {/* Título a la izquierda en*/}
                                        <Col xs={24} md={{ span: 12, order: 2 }}>
                                            <img
                                                className="img-featured"
                                                title={item.title}
                                                src={props.prefixImg + item.img}
                                                alt={item.title}
                                            />
                                        </Col>
                                        {/* Imagen a la derecha en desktop */}
                                        <Col xs={24} md={{ span: 12, order: 1 }} className='text-container'>
                                            <Typography.Paragraph
                                                ellipsis={{ rows: 4, expandable: true }}
                                                className="app-subtitle"
                                            >
                                                {item.title}
                                            </Typography.Paragraph>
                                        </Col>
                                    </>
                                )}
                            </Row>

                        </Link>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default NewsFeaturedListComponents;
