import { createContext, useContext, useEffect, useState } from "react";
import { CurrencyData, CurrencyAvailable } from "../models/Currency";
import AppConfig from "../models/AppConfig";
import UserSessionData from "../models/UserSessionData";

const defaultCurrencyData: CurrencyData = {
    currenciesAvailable: [], // Array con las tasas de cambio disponibles
    currencySelectedISO: "",
};

const CurrencyContext = createContext<{
    currencyData: CurrencyData;
    changeCurrencyISO: (newISO: string) => void; // Función para cambiar la moneda
    doExchangeCurrency: (originCurrency: string, destinationCurrency: string, quantity: number) => number; // Función para conversión
}>({
    currencyData: defaultCurrencyData,
    changeCurrencyISO: () => {},
    doExchangeCurrency: () => 0,
});

export const CurrencyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currencyData, setCurrencyData] = useState<CurrencyData>(defaultCurrencyData);

    useEffect(() => {
        const appConfig = sessionStorage.getItem("app-config");
        const userDataSession = sessionStorage.getItem("user-data");

        if (appConfig) {
            const appConfigurationObject: AppConfig = JSON.parse(appConfig) as AppConfig;
            const currenciesExchange = appConfigurationObject.currenciesExchange;

            // Actualizamos el estado con las monedas disponibles
            setCurrencyData((prev) => ({
                ...prev,
                currenciesAvailable: currenciesExchange,
            }));
        }

        if (userDataSession) {
            const userData: UserSessionData = JSON.parse(userDataSession) as UserSessionData;

            // Actualizamos el estado con la moneda seleccionada
            setCurrencyData((prev) => ({
                ...prev,
                currencySelectedISO: userData.userData.market,
            }));
        }
    }, []);

    // Función para cambiar la moneda seleccionada
    const changeCurrencyISO = (newISO: string): void => {
        setCurrencyData((prev) => ({
            ...prev,
            currencySelectedISO: newISO,
        }));
    };

    // Función para realizar la conversión de monedas
        // moneda origen -> moneda destino  ------>  paso de moneda origen a EUR (dividiendo por factor de conversión de la moneda origen) 
                                          // y luego a moneda destino (multiplicando por factor de conversión de la moneda destino)

    const doExchangeCurrency = (
        originCurrency: string,
        destinationCurrency: string,
        quantity: number
    ): number => {
        const { currenciesAvailable } = currencyData;
        const exchangeFactorConversionDestination = currenciesAvailable.find((c) => c.currencyISO === destinationCurrency) ?.exchangeConversionValue ?? 1;
        const exchangeFactorConversionOrigin = currenciesAvailable.find((c) => c.currencyISO === originCurrency) ?.exchangeConversionValue ?? 1;

        return Math.round((quantity * exchangeFactorConversionDestination * 100) / exchangeFactorConversionOrigin) / 100;
    };

    return (
        <CurrencyContext.Provider value={{ currencyData, changeCurrencyISO, doExchangeCurrency }}>
            {children}
        </CurrencyContext.Provider>
    );
};

// Hook para acceder al contexto
export const useCurrencyData = () => useContext(CurrencyContext);
