import { useTranslation } from "react-i18next";
import { FeaturedNewsList, NotFeaturedNewsList } from "../../../components";
import { useEffect, useState } from "react";
import './NewsInfoPage.scss';
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { NewsData, NewsFilter } from "../../../models/NewsInfo";
import { PageSize } from "../../../utils/constants";
import { fetchActiveNews, fetchNewsInfo } from "../../../api/repositoryEP";
import LoadingDataComponent from "../../../components/common/LoadingData/LoadingDataComponent";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";



const NewsInfoPage = (): JSX.Element => {

    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [newsData, setNewsData] = useState<NewsData>();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();



    useEffect(() => {
        if (id) {
            setLoadingData(true)
            fetchNewsInfo(id).then((response) => {
                console.log(response?.data)
                setNewsData(response?.data as NewsData)

                setLoadingData(false)
            });
        }
    }, [id]);

    const handleNavigateBack = () => {
        navigate(window.location.pathname.replace(`/news-page/${id}`, "/news-page")); // Elimina "new/:id"
    };



    return (

        <div >
            {loadingData && <LoadingDataComponent />}
            {!loadingData && (
                <>
                    <Row gutter={[16 , 0]}>
                        <Col xs={24} md={24}>
                            <span
                                className="app-text back-link"
                                onClick={handleNavigateBack}
                            >
                                <ArrowLeftOutlined className="back-button" />
                                {t("button.back")}
                            </span>
                        </Col>

                        <div className="news-info-component">

                            <Col xs={24} md={24} className="news-content">
                                {/* Título */}
                                {newsData?.title && (
                                    <div className="news-title">
                                        {newsData.title}
                                    </div>
                                       
                                )}

                                {/* Imagen Principal */}
                                {newsData?.img && (
                                    <div className="main-image-container">
                                        <img
                                            src={newsData.img}
                                            alt={newsData.title}
                                            className="main-image"
                                        />
                                    </div>
                                )}

                                {/* Fecha y Descuento */}
                                {newsData?.publishedFrom && (
                                    <div className="news-details">
                                        <p>{new Date(newsData.publishedFrom).toLocaleDateString()}</p>
                                    </div>
                                )}

                                {/* Descripción */}
                                {newsData?.description && (
                                    <div
                                        className="news-description"
                                        dangerouslySetInnerHTML={{ __html: newsData.description }}
                                    />
                                )}
                            </Col>
                        </div>
                    </Row>
                </>
            )}

        </div>
    )
}

export default NewsInfoPage;