import { useTranslation } from "react-i18next";
import { BookingsFilter, BookingsList } from "../../../components";
import { useEffect, useState } from "react";
import HotelInfo from "../../../models/HotelInfo";
import StatusInfo from "../../../models/StatusInfo";
import {  fetchUserList, fetchBookingList, synchronizeBookingStatus, fetchBookingData } from "../../../api/repositoryEP";
import UsersInfo, { UserFilterData } from "../../../models/UsersInfo";


import './BookingsPage.scss';
import BookingInfo, { BookingFilteData, SmallBookingDataList } from "../../../models/BookingInfo";
import { Drawer, notification } from "antd";
import HTMLReactParser from "html-react-parser";
import BookingDetailPage from "../BookingDetailPage/BookingDetailPage";
import BookingData from "../../../models/BookingData";
import { useNavigate } from "react-router-dom";
import { GetFormattedUrl } from "../../../utils/urls";
import { useSessionData } from "../../../Contexts/SessionDataContext";
import { useAppConfig } from "../../../Contexts/AppConfigContext";


const BookingsPage = () : JSX.Element => {
    
    const { t } = useTranslation();
    const appData = useAppConfig();

    const [hotels, setHotels] = useState<HotelInfo[]>([]);
    const [totalCommision, setTotalCommision] = useState<number>(0);
    const [totalPayed, setTotalPayed] = useState<number>(0);    
   
    const [users, setUsers] = useState<UsersInfo[]>([]);
    const [bookingListInfo, setBookingListInfo] = useState<SmallBookingDataList>({
        elements: [],
        totalRecords: 0,
        totalComission : 0 ,
        totalPvp : 0 
    });
    const [synchronizingId, setSynchronizingId] = useState<string>('');
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [showBookingDetailDrawer, setShowBookingDetailDrawer] = useState<boolean>(false);

    const getHotels = () : void => {
       const hotels =  appData.config.hotels;
       let hotelList : HotelInfo[] = [];
       hotels.forEach(hotel => {
        hotelList.push( {id: hotel.id, name: hotel.name});
       });
       setHotels(hotelList);
    }
     const status: StatusInfo[] = [
        { id: 0, name: "pending" },
        { id: 1, name: "pre" },
        { id: 2, name: "active" },
        { id: 3, name: "canceled" },
        { id: 4, name: "error" }
    ];

   

  const userSessionData = useSessionData();

    const getUsers = () : void => {
        fetchUserList({
            name: '',
            email: '',
            username: '',
            phone: '',
            custom: [],
            numRecords: 9999,
            page: 1,
        } as UserFilterData,userSessionData.userData.typeAccount,userSessionData.userData?.idOrganization).then((response) => {
            if (response && response.data && response.data.elements){
                setUsers(response.data.elements);
              
            }else{
                setUsers([]);
            }
        });
    }

    const [lastFilterData, setLastFilterData] = useState<BookingFilteData>({
                                                                                checkIn: null,
                                                                                checkOut: null,
                                                                                hotelId: '',
                                                                                locator: '',
                                                                                ownerName: '',
                                                                                recordDate: null,
                                                                                status: '',
                                                                                userId: '',
                                                                                numRecords: 6,
                                                                                page: 1
                                                                            });


    const getBookings = (filter: BookingFilteData) : void => {
        filter.numRecords = lastFilterData.numRecords;
        filter.page = lastFilterData.page;
        filter.userId = userSessionData.userData.id;
        setLoadingData(true);
        fetchBookingList(filter).then((response) => {
            if (response?.type == 1 && response.data ){
                const responseBookings = response.data as SmallBookingDataList;
                console.log(responseBookings);
                setBookingListInfo(responseBookings);


            }else{
                setBookingListInfo({
                    elements: [],
                    totalRecords: 0,
                    totalPvp : 0,
                    totalComission : 0
                });
            }
        }).finally(()=>{
            setLoadingData(false);
        }); 
    }

    const onFilter = (filter: BookingFilteData) : void => {
        getBookings(filter);
    }

    const onSearch = (rows: number, page: number) : void => {
        let newFilterdata = lastFilterData;
        newFilterdata.numRecords = rows;
        newFilterdata.page = page;

        getBookings(newFilterdata);
    }

    const [bookingDetail, setBookingDetail] = useState<BookingData>();
    const showBookingDetail = (bookingId: string) : void => {
        fetchBookingData(bookingId).then((response) => {
            if (response && response.data){
                setBookingDetail(response.data);
                setShowBookingDetailDrawer(true);
            }
        });
    }

    const navigate = useNavigate();
    const showCustomerVoucher = (bookingId: string) : void => {
        navigate(GetFormattedUrl(`private/customer-voucher/${bookingId}`));
    }

    const forceReloadBookingStatus = (bookingId: string, bookingLocator: string) : void => {
        if (synchronizingId.length > 0){
            notification.warning({
                message: t('pages.bookings-page.synchronizing'),
                description: HTMLReactParser(t('pages.bookings-page.synchronizing-description', [synchronizingId]))
            })
        }else{
            setSynchronizingId(bookingLocator);
            synchronizeBookingStatus(bookingId).then((response) => {
            }).finally(()=>{
                setSynchronizingId('');
            });
        }
    }

    useEffect(()=>{
        getHotels();
        getUsers();
        getBookings(lastFilterData);
    },[])


    return(
        <div className="bookings-page">
            <h2 className="title">{t('pages.bookings-page.main-title')}</h2>
            <h3 className="sub-title">{t('pages.bookings-page.subtitle')}</h3>

            <Drawer width={"100%"} open={showBookingDetailDrawer} title={bookingDetail ? HTMLReactParser(t('pages.bookings-page.booking-detail-title', [bookingDetail?.pmsLocator, bookingDetail?.hotelInfo.name]) ): ''} bodyStyle={{background: "#f5f5f5"}} onClose={()=>{
                setShowBookingDetailDrawer(false);
            }}>
                <BookingDetailPage bookingData={bookingDetail}/>
            </Drawer>

            <div className="bookings-filter">
                <BookingsFilter loading={loadingData} hotelList={hotels} statusList={status} userList={users} onFilter={onFilter}/>
            </div>

            <div className="bookings-list">
                <BookingsList loading={loadingData} totalElements={bookingListInfo.totalRecords}  totalCommision={bookingListInfo.totalComission} totalPvp={bookingListInfo.totalPvp} bookingsInfo={bookingListInfo} search={onSearch} onForceReloadBookingStatus={forceReloadBookingStatus} onShowBookingDetail={showBookingDetail} onShowCustomerVoucher={showCustomerVoucher} synchronizingId={synchronizingId}/>
            </div>
        </div>
    )
}

export default BookingsPage;