
import { BookingCart, Summary } from '@beds2b-group/reusable-components'
import './CartComponent.scss'
import { CartReservationInfo, HotelProperties, Reservation } from '@beds2b-group/reusable-components/dist/types'
import { useEffect, useState } from 'react'
import { useCart } from '../../../Contexts/CartContexts'
import { Button, Dropdown, MenuProps } from 'antd'
import { CheckCircleOutlined, ClockCircleOutlined, PrinterOutlined, SendOutlined, UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface CartComponentProps {
    onClickInPayButton: () => void,
    hotelData: HotelProperties,
    from: Date | null | undefined,
    to: Date | null | undefined,
    notifyRemoveRoom: (roomIndex: number, roomCode: string) => void,
}


const CartComponent = (props: CartComponentProps) => {
    const { t } = useTranslation();
    
    const cartContext = useCart();

    const [cartDetails, setCartDetails] = useState<CartReservationInfo>(cartContext.data)

    const onRemoveService = (serviceIndex: number) => {
        if (cartDetails) {
            const newCartDetails = { ...cartDetails };
            const services = cartDetails.services.filter(item => item.serviceIndex !== serviceIndex);
            newCartDetails.services = services;

            setCartDetails(newCartDetails)
        }
    }

    const onRemoveRoom = (roomIndex: number, roomCode: string) => {
        if (cartDetails) {
            props.notifyRemoveRoom(roomIndex, roomCode);

            const newCartDetails = { ...cartDetails };
            const rooms = cartDetails.rooms.filter(item => item.roomIndex !== roomIndex);
            newCartDetails.rooms = rooms;

            //Reduce cuantity of services that have the roomCode
            const services = cartDetails.services.map(item => {
                if (item.roomCodes.includes(roomCode)) {
                    item.roomCodes = item.roomCodes.filter(item => item !== roomCode);
                }


                return item;
            })

            const finalServices = services.filter(item => item.roomCodes.length > 0);

            newCartDetails.services = finalServices;

            console.log("onRemoveItem -> Final Rooms ->", newCartDetails);
            setCartDetails(newCartDetails)

            cartContext.removeRoomFromCart(roomIndex);
        }
    }
    

    const consoleCartContext = () => {
        console.warn("CartContext", cartContext.data);
    }

    const [isConfirmEnabled, setIsConfirmEnabled] = useState<boolean>(false);
    const toogleConfirmEnabled = () => {
        setIsConfirmEnabled(!isConfirmEnabled);
    }

    const sendCopyToEmail = () => {}

    const saveForLater = () => {}
    

    useEffect(() => {
        console.warn("Cambia el estado del carrito", cartContext.data);
        setCartDetails(cartContext.data);
    }, [cartContext])


    return (
        <div className={`cart-component`}>
            {
                cartDetails ?
                    <BookingCart
                        reservation={cartContext.data}
                        removeRoom={onRemoveRoom}
                        removeService={onRemoveService}
                        buttons={
                            [
                                <Button className="app-button full go-confirm" disabled={!isConfirmEnabled} onClick={props.onClickInPayButton}>
                                    <span className={`sol-icon-ok-circled`} /> {t('components.cart-component.go-confirm')}
                                </Button>,
                                <div className="other-actions">
                                    <Button className="app-button send-info" onClick={sendCopyToEmail}><SendOutlined /> {t('components.cart-component.send-cart')}</Button>
                                    <Button className="app-button save-info" onClick={saveForLater}><ClockCircleOutlined /> {t('components.cart-component.save-for-later')}</Button>
                                </div>
                            ]
                        }
                    />
                    :
                    <>
                        Carrito no disponible
                    </>
            }

            <Button size='small' className="app-button full" style={{marginTop: "10px"}} onClick={toogleConfirmEnabled}>
                <CheckCircleOutlined /> {t('components.cart-component.toogle-cart-status')}
            </Button>

            <Button size='small' className="app-button full" style={{marginTop: "10px"}} onClick={consoleCartContext}>
                <PrinterOutlined /> {t('components.cart-component.print-cart-status-in-console')}
            </Button>

        </div>
    )
}

export default CartComponent