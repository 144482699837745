  import { DestinationListChilds, DestinationListItem, HistoryItem, HotelProperties, SelectedRoom } from "@beds2b-group/reusable-components/dist/types"
  import { FC, useEffect, useState } from "react"
  import { useBookingEngineContext } from "../../../Contexts/EngineDataContext"
  import { useNavigate } from "react-router-dom"
  import { GetFormattedUrl } from "../../../utils/urls"
  import { useAppConfig } from "../../../Contexts/AppConfigContext"
  import { getRandomItem } from "../../../mocks/calendar"
  import { t } from "i18next"
  import { Place } from "../../../utils/utils"
  import { AvailableDestinationsList } from "../../../models/HotelInfo"

  import hotelUtils from "../../../mocks/hotels"
  import moment from "moment"

  import { WidgetWrapper } from "@beds2b-group/reusable-components"

  import '../MapModalWrapper'

  import { useModal } from "@gluedigital/modal"
  import { EngineDestinationTypeHotel, EngineDestinationTypeLocation } from "../../../utils/constants"
  import { GoogleMaps } from "../.."
  import { useGoogleMapsContext } from "../../../Contexts/GoogleMapsContext"
  import { useCart } from "../../../Contexts/CartContexts"
  import { Button, notification } from "antd"

  import './WidgetComponent.scss'
import { ReloadOutlined } from "@ant-design/icons"


  interface SearchStatusProps {
    value: string
    hasResults: boolean,
  }



  const RelatedItemComponent: FC<{ item: HotelProperties; handleSelect: (item: HotelProperties) => void; }> = ({ item, handleSelect }) => {
    // Aquí puedes usar las props `item` y `handleSelect` para renderizar tu componente
    return <div>Related Items</div>;
  };


  const minLength = 3
  function SearchStatus({ value, hasResults }: SearchStatusProps) {
    if (hasResults) return null

    if (value.length < minLength)
      return (
        <li className="status short" style={{ margin: 32, listStyle: 'none' }}>
          Escribe al menos 3 letras para buscar...
        </li>
      )

    return (
      <li className="status no-results" style={{ margin: 32, listStyle: 'none' }}>
        Sin resultados
      </li>
    )
  }

  interface WidgetComponentProps {
    userHistory: Array<HistoryItem>,
    searchCoincidences: Array<any>,
    availableDestinations: Array<AvailableDestinationsList>,
    onGetCoincidences: (value: string) => void,
  }

  const WidgetComponent = (props: WidgetComponentProps): JSX.Element => {
    const cartContext = useCart();
    const modal = useModal()

    const navigate = useNavigate();
    const appConfig = useAppConfig();

    const bookingEngineContext = useBookingEngineContext();

    const { userHistory, onGetCoincidences, searchCoincidences, availableDestinations } = props;

    const onChangeSearchValue = (value: string) => {
      onGetCoincidences(value);
    }

    const [countries, setCountries] = useState<Array<DestinationListItem>>([]);
    const setInitialCountries = (data: Array<AvailableDestinationsList>) => {
      // obtenemos los countries para ponerlos en countries usando setCountries:
      const countryList: Array<DestinationListItem> = data.map((d: AvailableDestinationsList) => {
        return {
          id: d.countryCode,
          label: d.countryName,
          component: <Place place={d.countryName} hotelsNumber={d.cities.length} />,
        }
      })

      setCountries(countryList);
    };

    const handleSelectCountry = (id: string): Array<DestinationListItem> => {
      // Este método filtra para el listado de hoteles por el país, mostrando para ese país cuáles son las ciudades que tienen hoteles.
      const country = availableDestinations.find((d: AvailableDestinationsList) => d.countryCode === id);

      sessionStorage.setItem('selectedCountryCode', id);


      const cities = Array.from(
        new Map(
          country?.cities.map((c) => [c.cityCode, {
            id: c.cityCode,
            label: c.cityName,
            component: <Place place={c.cityName} hotelsNumber={c.hotels.length} />,
          }])
        ).values()
      ) as Array<DestinationListItem>;

      return cities;
    }

    const handleSelectRegion = (id: string): Array<DestinationListItem> => {
      var selectedCountryCode = sessionStorage.getItem('selectedCountryCode');
      const country = availableDestinations.find((d: AvailableDestinationsList) => d.countryCode === selectedCountryCode);
      const region = country?.cities.find((c) => c.cityCode === id);
      const hotels = region?.hotels.map((h) => {
        return {
          id: h.hotelCode,
          label: h.hotelName,
          component: <p style={{ margin: 0 }}>
            <span>{h.hotelName}</span>
          </p>,
        }
      }) as Array<DestinationListItem>;


      return hotels;
    }

    async function handleSelect(id: string, category: string): Promise<DestinationListChilds | null> {
      //Este método se encarga de manejar la selección de un item en el listado de destinos
      var result = null;
      if (category === 'countries') {
        // Aquí entra cuando se selecciona un país
        result = {
          category: 'regions',
          items: handleSelectCountry(id),
          header: <h4>{t('components.widget-component.regions')}</h4>,
        }
      } else if (category === 'regions') {
        //Aquí entra cuando se selecciona una región
        sessionStorage.setItem('selectedRegionCode', id);

        result = {
          category: 'hotels',
          items: handleSelectRegion(id),
          header: <h4>{t('components.widget-component.hotels')}</h4>,
        }
        setLastSearchType(EngineDestinationTypeLocation);

      } else {
        //Aquí entra cuando desde el listado se selecciona un hotel
        sessionStorage.setItem('lastSearchCode', id);
        sessionStorage.setItem('lastSearchType', "0");
      }

      return result;
    }

    const [selectedHotel, setSelectedHotel] = useState<{ name: string, code: string }>({ name: '', code: '' });

    const searchConf = {
      history: userHistory,
      list: searchCoincidences,
      mapObject: <GoogleMaps apiKey={appConfig.config.scripts.find(script => script.key === 'google-maps-api-key')?.value}
        hotelList={appConfig.config.hotels}
        onSelectedHotel={(item) => {
          console.log("WidgetComponent.tsx -> Tras elegir en el mapa", item);
          setSelectedHotel(item);
        }} />
    };

    //Aquí hay un problema y es que cuando se llama a handleSelect el componente se vuelve a refrescar y destination
    //list vuelve a tener el estado inicial y se pierde la selección. Para no rehacer todo el componente se ha guardado el código
    //del país en el sessionStorage.
    const destinationList = {
      category: 'countries',
      elements: countries,
      handleSelectItem: ({ category, selection }: any) => {
        /**NOTA: Este es el método que se invoca cada vez que  se pulsa algo en el listado.*/
        console.log('Handle handleSelectItem', { category, selection })
        sessionStorage.setItem("lastSearchCode", selection.id);
        return handleSelect(selection.id, category);
      },
      header: <h4>{t('components.widget-component.destinations')}</h4>,
    };

    const [lastSearchType, setLastSearchType] = useState<number>(1);
    const [lastSearchCode, setLastSearchCode] = useState<string>('');

    useEffect(() => {
      setInitialCountries(availableDestinations)
    }, [availableDestinations])

    const googleMapsContext = useGoogleMapsContext();
    useEffect(() => {
      const googleMapsApiKey = appConfig.config.scripts.find(script => script.key === 'google-maps-api-key')?.value;
      googleMapsContext.initMapScript(googleMapsApiKey);
    }, []);

    const onSelectListItem = (item: any) => {
      console.log(`current selection... ${item}`, item);
    }

    const [initialFrom, setInitialFrom] = useState<Date | null>(null);
    const [initualTo, setInitialTo] = useState<Date | null>(null);
    const [defaultLabel, setDefaultLabel] = useState<string>('');

    useEffect(()=>{
      var dateFrom = sessionStorage.getItem("lastFrom");
      var dateTo = sessionStorage.getItem("lastTo");
      var defaultLabel = sessionStorage.getItem("lastLabel");

      if (dateFrom && dateFrom != 'Invalid date'){
        setInitialFrom(new Date(dateFrom));
      }

      if (dateTo && dateTo != 'Invalid date'){
        setInitialTo(new Date(dateTo));
      }

      if (defaultLabel){
        setDefaultLabel(defaultLabel);
      }

    },[props])

    const [haveSearchDataInSessionStorage, setHaveSearchDataInSessionStorage] = useState<boolean>(false);
    const clearSearchItems = () => {
      sessionStorage.removeItem("lastSearchType");
      sessionStorage.removeItem("lastSearchCode");
      sessionStorage.removeItem("lastOccupancy");

      sessionStorage.removeItem("lastLabel");
      sessionStorage.removeItem("selectedCountryCode");
      sessionStorage.removeItem("selectedRegionCode");

      sessionStorage.setItem("lastFrom", "Invalid date");
      sessionStorage.setItem("lastTo", "Invalid date");



      setInitialFrom(null);
      setInitialTo(null);
      setDefaultLabel('');
      setHaveSearchDataInSessionStorage(false);

      cartContext.clearCart();
      bookingEngineContext.clearContext();

      window.location.reload();
    }

    useEffect(()=> {
      if (sessionStorage.getItem("lastSearchCode")){
        setHaveSearchDataInSessionStorage(true);
      }else{
        setHaveSearchDataInSessionStorage(false);
      }
    }, []);

    return <div className="widget-component">
      <div className="widget-actions">
        {
          haveSearchDataInSessionStorage && 
                      <Button onClick={clearSearchItems} className="app-button">
                          <ReloadOutlined /> {t('components.widget-component.clear-search')}
                      </Button>
        }
      </div>
      <WidgetWrapper
        defaultLabel={defaultLabel}
        initialFrom={initialFrom ?? null}
        initialTo={initualTo ?? null}
        handleChangeValue={async (value: any) => {
          console.log("handleChangeValue in WidgetComponent.tsx", value);
          sessionStorage.setItem("lastLabel", value);
          setDefaultLabel(value);
          onChangeSearchValue(value);
        }}
        map={{
          handleShowMap: (props) => modal.show('map-modal', props),
          closeMap: () => {
            modal.hide();
          },
          items: [hotelUtils.hotel],
          mapKey: 'AIzaSyDIdhJZxHzb9cNHbj_e10MEeRH6zYynVlY',
        }}

        search={searchConf}

        destinationList={destinationList}

        handleClickSearch={function (d): Promise<void> {
          console.log("Push search:", d);

          sessionStorage.setItem("lastOccupancy", JSON.stringify(d.rooms));

          //Limpiamos el carrito
          cartContext.clearCart();

          var sessionStorageSearchTipe = sessionStorage.getItem("lastSearchType");

          let searchType =  sessionStorageSearchTipe ? parseInt(sessionStorageSearchTipe) : EngineDestinationTypeLocation;
          let searchCode = sessionStorage.getItem("lastSearchCode") ?? undefined;
          var dateFrom = sessionStorage.getItem("lastFrom");
          var dateTo = sessionStorage.getItem("lastTo");


          var label = sessionStorage.getItem("lastLabel") ?? bookingEngineContext.label;

          let theRooms = d.rooms.map((r: any) => {
            return {
              persons: {
                adults: {
                  quantity: r.persons.adults.quantity,
                  min: r.persons.adults.min,
                  max: r.persons.adults.max
                },
                kids: {
                  ages: r.persons.kids.ages ?? [],
                  quantity: r.persons.kids.quantity,
                  min: r.persons.kids.min,
                  max: r.persons.kids.max
                }
              },
              roomId: r.roomId
            }
          });

          var errorInSearch: boolean = false;
          //TODO: Hay que hacer que cuando se selecciona algo venga el code en el widget
          if (!searchCode || searchCode === '') {
            errorInSearch = true;
            notification.warning({
              message: t('components.widget-component.alert'),
              description: t('components.widget-component.error-select-destinations'),
            })
          } else if (dateFrom === null || dateTo === null || dateFrom === 'Invalid date' || dateTo === 'Invalid date'  || !moment(dateTo).isValid() || !moment(dateFrom).isValid()) {
            errorInSearch = true;
            notification.warning({
              message: t('components.widget-component.alert'),
              description: t('components.widget-component.error-select-dates'),
            })
          }

          if (!errorInSearch) {
            let newContext = {
              searchType: searchType,
              searchCode: searchCode,
              promotionalCode: d.code,
              label: label,
              dates: {
                from: new Date(dateFrom!),
                to: new Date(dateTo!),
              },
              rooms: theRooms
            };

            bookingEngineContext.updateContext(newContext);
            setHaveSearchDataInSessionStorage(true);
            if (window.location.pathname !== GetFormattedUrl('private/booking-engine')) {
                sessionStorage.setItem("lastSearchType", searchType.toString());
                sessionStorage.setItem("lastSearchCode", searchCode! );
                sessionStorage.setItem("lastFrom", moment(dateFrom).format('YYYY-MM-DD'));
                sessionStorage.setItem("lastTo", moment(dateTo).format('YYYY-MM-DD'));
                navigate(GetFormattedUrl('private/booking-engine'));
                //navigate(GetFormattedUrl(`private/booking-engine?${searchType===EngineDestinationTypeLocation ? 'cc' : 'hc'}=${searchCode}&df=${moment(d.dates.from).format('YYYY-MM-DD')}&dt=${moment(d.dates.to).format('YYYY-MM-DD')}`));
            }

          }

          return Promise.resolve();
        }}

        status={SearchStatus}

        calendar={{
          RelatedItemComponent: RelatedItemComponent,
          ValueComponent: (props) => <div className="app-calendar-pvp-details">{props.price.pvp.toFixed(2)}</div>,
          relatedHotels: [],
          config: { maxDuration: 10, minAntelation: 1, minDuration: 2 },
          // Cuando se lean los datos de los precios diarios desde la API se deben poner en este DATA
          data: {
            '2024-01': Array.from({ length: 31 }, getRandomItem),
            '2024-02': Array.from({ length: 31 }, getRandomItem),
            '2024-03': Array.from({ length: 31 }, getRandomItem),
            '2024-04': Array.from({ length: 31 }, getRandomItem),
            '2024-05': Array.from({ length: 31 }, getRandomItem),
            '2024-06': Array.from({ length: 31 }, getRandomItem),
            '2024-07': Array.from({ length: 31 }, getRandomItem),
            '2024-08': Array.from({ length: 31 }, getRandomItem),
            '2024-09': Array.from({ length: 31 }, getRandomItem),
            /*'2024-10': Array.from({ length: 31 }, getRandomItem),
            '2024-11': Array.from({ length: 30 }, getRandomItem),
            '2024-12': Array.from({ length: 31 }, getRandomItem),
            '2025-01': Array.from({ length: 31 }, getRandomItem),
            '2025-02': Array.from({ length: 31 }, getRandomItem),
            '2025-03': Array.from({ length: 31 }, getRandomItem),
            '2025-04': Array.from({ length: 31 }, getRandomItem),
            '2025-05': Array.from({ length: 31 }, getRandomItem),
            '2025-06': Array.from({ length: 31 }, getRandomItem),
            '2025-07': Array.from({ length: 31 }, getRandomItem),
            '2025-08': Array.from({ length: 31 }, getRandomItem),
            '2025-09': Array.from({ length: 31 }, getRandomItem),
            '2025-10': Array.from({ length: 31 }, getRandomItem),
            '2025-11': Array.from({ length: 30 }, getRandomItem),
            '2025-12': Array.from({ length: 31 }, getRandomItem),*/
          } as any,
          handleInterval: function (interval, status): void {
            console.log("handleInterval", interval, status);
            if (interval.from ) {
              sessionStorage.setItem("lastFrom", interval.from.toISOString());
            }
            if (interval.to ) {
              sessionStorage.setItem("lastTo", interval.to.toISOString());
            }
          },
          handleUpdateData: function (key: string): Promise<void> { console.error("Function not implemented 5."); return Promise.resolve(); }
        }}

        roomType={{ options: appConfig.config.widget.availableRoomTypes as SelectedRoom[] }}

        rooms={{
          // Sirve para especificar los datos de la primera habitación de múltiple
          defaultRoom: {
            persons: {
              adults: {
                quantity: 2,
                min: appConfig.config.widget.minGuestsPerRoom,
                max: appConfig.config.widget.maxAdultsPerRoom,
              },
              kids: {
                quantity: 0,
                min: 0,
                max: appConfig.config.widget.maxChildrenPerRoom,
                ages: [],
              },
            },
            roomId: 1,
          },
          handlePersonsAge: async (roomId, index, value) =>
            //Método para actualizar la edad de un niño
            console.log(
              `a) room with id:${roomId}, index:${index} updated age with value:${value}`
            ),
          handlePersonsAmount: async (type, roomId, value) =>
            //Método para actualizar la cantidad de personas
            console.log(
              `b) room with id:${roomId}, type: ${type} updated amount with value:${value}`
            ),
          maxGuests: appConfig.config.widget.maxGuestsPerRoom,
          minGuests: appConfig.config.widget.minGuestsPerRoom,
          maxRooms: appConfig.config.widget.maxRooms,
          minRooms: appConfig.config.widget.minRooms,
        }}

        handleHotelSelection={onSelectListItem}

        retrievers={{
          retrieveItemsByName: (v: any) => {
            console.log("retrieveItemsByName", v)
            throw new Error("retrieveItemsByName is not implemented");
          },
          retrieveItemsByCategory: (searchType: any, searchData: any) => {
            try {
              (document.activeElement as HTMLElement)?.blur();
            } catch { }
            console.log("retrieveItemsByCategory", searchType, searchData);

            sessionStorage.setItem("lastSearchType", (searchType == 'hotel' ? EngineDestinationTypeHotel : EngineDestinationTypeLocation).toString());
            sessionStorage.setItem("lastSearchCode", searchData.id);

            return Promise.resolve([]);
          },
          retrieveItemsById: (v: any) => {
            console.log("retrieveItemsById", v)
            return Promise.resolve([v]);
          },
        }}
      />
    </div>
  }

  export default WidgetComponent;