
import LoginFormComponent from "./LoginForm/LoginForm";
import ForgetPasswordFormComponent from "./ForgetPasswordForm/ForgetPasswordForm";   
import DotsActionsComponent from "./common/DotsActions/DotsActionsComponent";

//Register Components
import AgencySelectorComponent from "./Register/AgencySelector/AgencySelectorComponent";
import CompanySelectorComponent from "./Register/CompanySelector/CompanySelectorComponent";
import CorporateFormComponent from "./Register/CorporateForm/CorporateFormComponent";
import PersonalFormComponent from "./Register/PersonalDataForm/PersonalFormComponent";
import AgencyAndCompanyAddFormComponent from './Register/AgencyAndCompanyAddForm/AgencyAndCompanyAddForm';
import GoogleMapsAutoCompleteComponent from "./google/geocode/GoogleMapsAutoCompleteComponent";
import GoogleMapsComponent from "./google/maps/GoogleMapsComponent";
import AgencyAndCompanyRequestSignUpFormComponent from './Register/AgencyAndCompanyRequestSignUpForm/AgencyAndCompanyRequestSignUpForm';
import VerifyEmailComponent from './Register/VerifyEmail/VerifyEmailComponent';
import WaigintEmailSignUpComponent from "./Register/WaitingEmailSignUp/WaigintEmailSignUpComponent";

//Contact components
import ContactPhonesComponent from './common/contact/ContactPhonesComponent';
import ContactPhoneComponent from './common/contact/ContactPhoneComponent';
import ContactFormComponent from './common/contact/ContactFormComponent';
import GroupContactFormComponent from './GroupContactForm/GroupContactFormComponent'

//User components
import UsersFilterComponent from './Users/filter/UsersFilterComponent';
import UsersAddComponent from './Users/add/UsersAddComponent';
import UsersListComponent from './Users/list/UsersListComponent';
import UsersEditComponent from './Users/edit/UsersEditComponent';

//Booking components
import BookingsFilterComponent from './Bookings/filter/BookingsFilterComponent';
import BookingsListComponent from './Bookings/list/BookingsListComponent';
import CancellationPoliciesComponent from './Bookings/cancellationpolicies/CancellationPoliciesComponent';
import OccupationComponent from './Bookings/occupation/OccupationComponent';

//Statistics components
import StatisticsComponent from './statistics/StatisticComponent';

//Profile components
import ChangePasswordComponent from "./Profile/ChangePassword/ChangePasswordComponent";
import EditProfileComponent from "./Profile/EditProfile/EditProfileComponent";
import PersonalDataComponent from "./Profile/PersonalData/PersonalDataComponent";

//Engine components
import EngineCodeComponent from './booking-engine/CodeComponent/CodeComponent';
import WidgetComponent from './booking-engine/Widget/WidgetComponent';
import PaginationComponent from './booking-engine/Pagination/PaginationComponent';
import CartComponent from './booking-engine/Cart/CartComponent';
import BookingDataForm from './booking-engine/BookingDataForm/BookingDataForm';
import BookingPaymentComponent from './booking-engine/BookingPayment/BookingPaymentComponent';
import PostponedBookingsComponent from './booking-engine/PostponedBookings/PostponedBookingsComponent';
import NewsFeaturedListComponents from "./news/featuredNews/NewsFeaturedListComponents";
import NewsNotFeaturedListComponents from "./news/notFeaturedNews/NewsNotFeaturedListComponents";
import GeneralPaginationComponent from "./common/Pagination/GeneralPaginationComponent";
import NoDataComponent from "./common/NoData/NoDataComponent";

//Exported components
export const LoginForm = LoginFormComponent;
export const ForgetPassword = ForgetPasswordFormComponent;
export const AgencySelector = AgencySelectorComponent;
export const CompanySelector = CompanySelectorComponent;
export const CorporateForm = CorporateFormComponent;
export const PersonalForm = PersonalFormComponent;
export const AgencyAndCompanyAddForm = AgencyAndCompanyAddFormComponent;
export const GoogleMapsAutoComplete = GoogleMapsAutoCompleteComponent;
export const GoogleMaps = GoogleMapsComponent
export const AgencyAndCompanyRequestSignUpForm = AgencyAndCompanyRequestSignUpFormComponent;
export const VerifyEmail = VerifyEmailComponent;
export const WaigintEmailSignUp = WaigintEmailSignUpComponent;
export const ContactPhone = ContactPhoneComponent;
export const ContactForm = ContactFormComponent;
export const ContactPhones = ContactPhonesComponent;
export const UsersFilter = UsersFilterComponent;
export const UsersAdd = UsersAddComponent;
export const UsersList = UsersListComponent;
export const UsersEdit = UsersEditComponent;
export const BookingsFilter = BookingsFilterComponent;
export const BookingsList = BookingsListComponent;
export const FeaturedNewsList = NewsFeaturedListComponents;
export const NotFeaturedNewsList = NewsNotFeaturedListComponents;
export const CancellationPolicies = CancellationPoliciesComponent;
export const Occupation = OccupationComponent;
export const Statistics = StatisticsComponent;
export const ChangePassword = ChangePasswordComponent;
export const EditProfile = EditProfileComponent;
export const PersonalData = PersonalDataComponent;
export const GroupContactForm = GroupContactFormComponent;
export const DotsActions = DotsActionsComponent;

export const EngineCode = EngineCodeComponent;
export const EngineWidget = WidgetComponent;
export const Pagination = PaginationComponent;
export const Cart = CartComponent;
export const BookingPayment = BookingPaymentComponent;
export const BookingData = BookingDataForm;
export const PostponedBookings = PostponedBookingsComponent;
export const CustomPagination = GeneralPaginationComponent;
export const NoData = NoDataComponent;